<template>
	<div>
		<el-form ref="form" :rules="rules" :model="form" label-position="top">
			<div class="row">
				<div class="col-sm-6 col-md-8">
					<el-form-item label="Nombre" prop="name">
						<el-input v-model="form.name"></el-input>
					</el-form-item>
				</div>
				<div class="col-sm-6 col-md-4">
					<el-form-item label="Fecha de alta">
						<el-date-picker
							v-model="form.date_form"
							type="datetime"
							:disabled="true"
							placeholder="Select date and time">
						</el-date-picker>
					</el-form-item>
				</div>
				<div class="col-sm-6 col-md-4">
					<el-form-item label="Dirección" prop="address">
						<el-input v-model="form.address"></el-input>
					</el-form-item>
				</div>
				<div class="col-sm-6 col-md-4">
					<el-form-item type="number" label="Telefono" prop="phone">
						<el-input v-model="form.phone"></el-input>
					</el-form-item>
				</div>
				<div class="col-sm-6 col-md-4">
					<el-form-item label="Usuarios Skype">
						<el-input v-model="form.skype_user"></el-input>
					</el-form-item>
				</div>
				<div class="col-sm-6 col-md-4">
					<el-form-item label="Email" prop="email">
						<el-input type="email" v-model="form.email"></el-input>
					</el-form-item>
				</div>
				<div class="col-sm-6 col-md-3">
					<el-form-item label="Edad" prop="age">
						<el-input type="number" v-model="form.age"></el-input>
					</el-form-item>
				</div>
				<div class="col-sm-6 col-md-3">
					<el-form-item label="Altura: (en cm) Ej. 165" prop="height">
						<el-input v-model="form.height"></el-input>
					</el-form-item>
				</div>
				<div class="col-sm-6 col-md-2">
					<el-form-item label="Peso: (en kg) Ej. 50.5" prop="weight">
						<el-input v-model="form.weight"></el-input>
					</el-form-item>
				</div>
			</div>
		</el-form>
		<div class="row justify-content-center pt-4 mt-4 border-top">
			<div class="col-10 col-md-6 col-lg-4">
				<el-button class="btn-block" @click="next('form')" type="info" plain>Next step</el-button>
			</div>
		</div>

	</div>
</template>

<script>
	
	export default {
		data() {
			return {
				form: {
					name:'',
					date_form:'',
					address:'',
					phone:'',
					skype_user:'',
					email:'',
					age:'',
					height:'',
					weight:'',
				},
				rules: {
					name: [
						{ required: true, message: 'Campo requerido', trigger: 'blur' },
						{ min: 3, message: 'Longitud minima de 3 caracteres', trigger: 'blur' }
					],
					address: [
						{ required: true, message: 'Campo requerido', trigger: 'blur' }
					],
					phone: [
						{ required: true, message: 'Campo requerido', trigger: 'blur' }
					],
					email: [
						{ type: 'email', required: true, message: 'Por favor introduzca un email valido', trigger: 'blur' }
					],
					age: [
						{ type:'number', required: true, message: 'Campo requerido', trigger: 'blur' },
					],
					height: [
						{ required: true, message: 'Campo numerico requerido', trigger: 'blur' }
					],
					weight: [
						{ required: true, message: 'Campo numerico requerido', trigger: 'blur' }
					]
				}
			}
		},
		watch: {
			'form.age':function() {
				this.form.age = parseInt(this.form.age);
			},
			// 'form.height':function() {
			//     let transform = parseFloat(this.form.height);
			//     if(!isNaN(transform)){
			// 		this.form.height = transform;
			// 	}
			// },
			// 'form.weight':function() {
			// 	this.form.weight = parseInt(this.form.weight);
			// }
		},
		created() {
			this.form.date_form = moment().format('YYYY/MM/DD hh:mm:ss');
		},

		methods: {
			next(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						EventBus.$emit('user-data', this.form);
					} else {
						this.$notify({
							title: 'Rellene los campos correctamente',
							type: 'warning'
						});
					}
				});
			}
		},
	}

</script>